import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
    name: 'ims',
    components: {
        ModelSelect,
        DatePicker,
        commonHelper
    },
    watch: {
        searchParams() {
            if (this.searchParams) {
                this.getImsData(this.searchParams);
            } else {
                this.imsData = [];
            }
        },
        totalRows() {
            this.$emit('totalRowsCount', this.totalRows);
        },
        currentPage: function () {
            this.searchParams._page = this.currentPage - 1;
            this.getImsData(this.searchParams);
        }
    },
    props: ['searchParams', 'inv_num'],
    data() {
        return {
            currentPage: 1,
            totalRows: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            payload: null,
            unsubscribe: null,
            loader: false,
            tab: null,
            imsData: [
            ],
            imsFields: [
                {
                    key: 'ims_status',
                    label: 'IMS',
                    stickyColumn: true,
                },
                {
                    key: 'approve',
                    stickyColumn: true
                },
                {
                    key: 'reject',
                    stickyColumn: true
                },
                {
                    key: 'pending',
                    stickyColumn: true
                },
                {
                    key: 'nat',
                    label: 'NAT',
                    stickyColumn: true
                },
                {
                    key: 'action',
                    stickyColumn: true,
                },
                {
                    key: 'source_type',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'cess_amount',
                    label: 'CESS',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'igst_amount',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'cgst_amount',
                    label: 'CGST Amount',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'sgst_amount',
                    label: 'SGST Amount',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'form_type',
                    class: 'col-fix'
                },
                {
                    key: 'gstn_no',
                    label: 'GSTN Supplier',
                    class: 'col-fix'
                },
                {
                    key: 'gstn_no_supp',
                    label: 'GSTN Supplier',
                    class: 'col-fix'
                },
                {
                    key: 'invoice_date',
                    label: 'Invoice Date',
                    class: 'col-fix'
                },
                {
                    key: 'invoice_num',
                    class: 'col-fix'
                },
                {
                    key: 'invoice_type',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'invoice_value',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'irn',
                    label: 'IRN',
                    class: 'col-fix'
                },
                {
                    key: 'irn_applicable',
                    label: 'IRN Applicable',
                    class: 'col-fix'
                },
                {
                    key: 'ispendactblocked',
                    label: 'Is Pendact Blocked',
                    class: 'col-fix'
                },
                {
                    key: 'note_date',
                    class: 'col-fix'
                },
                {
                    key: 'note_number',
                    class: 'col-fix'
                },
                {
                    key: 'original_invoice_date',
                    class: 'col-fix'
                },
                {
                    key: 'original_invoice_num',
                    class: 'col-fix'
                },
                {
                    key: 'status',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'submited_status',
                    stickyColumn: true,
                    class: 'col-fix'
                },
                {
                    key: 'supplier_filling_status',
                    class: 'col-fix'
                },
                {
                    key: 'supplier_ret_period',
                    class: 'col-fix'
                },
                {
                    key: 'taxable_value',
                    stickyColumn: true,
                    class: 'col-fix'
                }
            ],
            dtlId: null,
            invoiceNum: null
        };
    },
    mounted() {
        this.invoiceNum = this.inv_num;
        // this.getImsData();
    },
    methods: {
        getImsData(params) {
            let payload = this.searchParams;
            if (params) {
                payload = Object.assign(payload, params);
            }
            this.loader = true;
            this.$store
                .dispatch('itcReconciliation/getImsData', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.imsData = response.data.data.page
                        // .map(data => {
                        //     data.approve = false,
                        //     data.reject = false,
                        //     data.pending = false,
                        //     data.nat = false
                        //     return data;
                        // });
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        selectedRadio(item, flag) {
            if (flag === 'A') {
                item.pending = false;
                item.reject = false;
                item.nat = false;
                item.approve = true;
            } else if (flag === 'R') {
                item.pending = false;
                item.approve = false;
                item.nat = false;
                item.reject = true;
            } else if (flag === 'P') {
                item.reject = false;
                item.approve = false;
                item.nat = false;
                item.pending = true;
            } else if (flag === 'NAT') {
                item.pending = false;
                item.approve = false;
                item.reject = false;
                item.nat = true;
            }
        },
        // checkDuplicate(index) {
        //     if (
        //         this.imsData[index].approve ||
        //         this.imsData[index].reject ||
        //         this.imsData[index].pending ||
        //         this.imsData[index].nat) {

        //     }
        // },
        updateIms() {
            const filterData = this.imsData.filter((ele) =>
                ele.approve || ele.reject || ele.pending || ele.nat
            )
            //   if (filterData.length) {
            // const payload = {
            //     getparams: [
            //         {
            //             // dtl_id: item.dtl_id,
            //             // action: flag
            //         }
            //     ]
            // };
            const itemDetails = filterData.map(elem => {
                return {
                    dtl_id: elem.dtl_id,
                    action: elem.approve ? 'A' : elem.reject ? 'R' : elem.pending ? 'P' : 'NAT',
                };
            });
            const payload = {
                getparams: itemDetails ? itemDetails : null
            };
            this.loader = true;
            this.$store
                .dispatch('itcReconciliation/selectedRadio', payload)
                .then(resp => {
                    this.loader = false;
                    if (resp.status === 200) {
                        this.itcDataUpdationData = resp.data.data.page;
                        this.totalRows = resp.data.data.total_elements;
                        this.getImsData();
                        this.showAlert = true;
                        this.isSuccess = true;
                        this.respMessage = resp.data.message;
                    } else {
                        this.showAlert = true;
                        this.isSuccess = false;
                        this.respMessage = resp.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        }
        //   else {
        //     this.$bvToast.toast('Please select atleast one record', {
        //       title: 'Alert',
        //       variant: 'danger',
        //       solid: true
        //     });
        //   }
        // }
    },
    beforeDestroy() {
        // this.unsubscribe();
    }
};
